import React from "react";
import { IconTypes } from "../IconTypes";

const Icon = ({ icon, className, textSize, onClick }: IIcon) => (
  <span
    onClick={onClick}
    className={`inline-block material-icons-outlined ${className} ${textSize}`}
  >
    {icon}
  </span>
);


export interface IIcon {
  icon: IconTypes;
  className?: string;
  onClick?: any;
  textSize?:
    | "text-xs"
    | "text-sm"
    | "text-base"
    | "text-lg"
    | "text-xl"
    | "text-2xl"
    | "text-3xl"
    | "text-4xl"
    | "text-large";
}

export default Icon;