import * as React from "react";
import IconButton from "@mui/material/IconButton";

export const IconButtonComponent = ({
  id,
  ariaLabel,
  sx,
  content,
  size = "medium",
  color,
  disabled,
  disableRipple = false,
  edge,
  onClick,
}: IIconButton) => {
  return (
    <IconButton
      aria-label={ariaLabel}
      id={id}
      sx={sx}
      size={size}
      color={color}
      disabled={disabled}
      disableRipple={disableRipple}
      edge={edge}
      onClick={onClick}
    >
      {content}
    </IconButton>
  );
};

export interface IIconButton {
  id: string;
  ariaLabel: any;
  sx?: any;
  content?: any;
  disabled?: boolean;
  disableRipple?: boolean;
  onClick?: any;
  size?: "small" | "medium" | "large";
  edge?: "start" | "end";
  color?:
    | "inherit"
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}
