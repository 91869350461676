import * as React from "react";
import Badge from "@mui/material/Badge";

export const BadgeComponent = ({
  id,
  count,
  variant = "standard",
  color = "primary",
  size = "medium",
  overlap = "rectangular",
  content,
  invisible = false,
  max = 99,
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
}: IBadge) => {
  return (
    <Badge
      badgeContent={count}
      color={color}
      id={id}
      invisible={invisible}
      max={max}
      overlap={overlap}
      variant={variant}
      anchorOrigin={anchorOrigin}
    >
      {content}
    </Badge>
  );
};

export interface IBadge {
  content: any;
  id: any;
  count: any;
  width?: number;
  step?: number | null;
  min?: number;
  max?: number;
  size?: "small" | "medium";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  invisible?: boolean;
  overlap?: "circular" | "rectangular";
  variant?: "dot" | "standard";
  anchorOrigin?: { horizontal: "left" | "right"; vertical: "bottom" | "top" };
}
