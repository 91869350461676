import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

export const AutocompleteField = ({
  fieldId,
  onChange,
  placeholder,
  errorMessage,
  valid = true,
  disabled,
  options,
  value,
  width = "100%",
  sx = { width: width },
  optionsType = "plain",
  size = "small",
  autoComplete = "on",
  variant = "outlined",
}: IText) => {
  return (
    <Autocomplete
      id={fieldId}
      sx={sx}
      options={options}
      autoHighlight
      onChange={onChange}
      size={size}
      {...(value && { value })}
      disabled={disabled}
      {...(optionsType === "render"
        ? {
            renderOption: (props: JSX.IntrinsicAttributes, option: any) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
                key={option.id}
              >
                {option.renderLabel}
              </Box>
            ),
          }
        : {})}
      renderInput={(params: any) => (
        <TextField
          {...params}
          error={!valid}
          size={size}
          helperText={!valid && errorMessage}
          label={placeholder}
          id={fieldId}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: "auto-complete",
          }}
          autoComplete={autoComplete}
        />
      )}
    />
  );
};

export interface IText {
  fieldId: string;
  onChange: any;
  errorMessage?: string;
  valid?: any;
  options: any[];
  disabled?: boolean;
  placeholder?: string;
  value?: any;
  optionsType?: "plain" | "render";
  sx?: any;
  width?: any;
  size?: "small" | "medium";
  autoComplete?: "off" | "on";
  variant?: "outlined" | "filled" | "standard";
}
