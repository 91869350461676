import * as React from "react";
import Avatar from "@mui/material/Avatar";

export const AvatarComponent = ({ id, alt, src, sx, content }: IAvatar) => {
  return (
    <Avatar id={id} alt={alt} src={src} sx={sx}>
      {content}
    </Avatar>
  );
};

export interface IAvatar {
  id: string;
  alt: any;
  src?: any;
  sx?: any;
  content?: any;
}
