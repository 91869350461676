import React from "react";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
export const SwitchField = ({
  name,
  fieldId,
  labelName = "",
  onChange,
  checked,
  disabled,
  align = "end",
  size = "medium",
  edge,
  sx,
  color,
}: IText) => {
  return (
    <FormControlLabel
      id={fieldId}
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
          size={size}
          disabled={disabled}
          edge={edge}
          color={color}
        />
      }
      labelPlacement={align}
      label={labelName}
      checked={checked}
      sx={sx}
    />
  );
};

export interface IText {
  name: string;
  fieldId: string;
  onChange: any;
  checked: boolean;
  labelName?: string;
  disabled?: boolean;
  align?: "end" | "start" | "top" | "bottom";
  size?: "small" | "medium";
  edge?: "end" | "start";
  sx?: any;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}
