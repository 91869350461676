import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";

export const ChipComponent = ({
  id,
  label,
  variant = "filled",
  onClick,
  onDelete,
  deleteIcon,
  avatar,
  icon,
  color = "default",
  size = "small",
  sx,
}: IChip) => {
  return (
    <Chip
      label={label}
      sx={sx}
      variant={variant}
      id={id}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
      avatar={avatar ? <Avatar>{avatar}</Avatar>: <></>}
      icon={icon ? icon : <></>}
      color={color}
      size={size}
    />
  );
};

export interface IChip {
  id: string;
  label: string | React.ReactNode;
  variant?: "filled" | "outlined";
  onDelete?: any;
  onClick?: any;
  deleteIcon?: any;
  avatar?: any;
  sx?: any;
  icon?: any;
  size?: "small" | "medium";
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}
