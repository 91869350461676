import React from "react";

export const PageHeading = ({ menuName, pageTitle, subPageTitle }: IText) => {
  return (
    <div className="flex flex-col">
      <p className="text-sm">{menuName}</p>
      <div>
        <span className={`text-4xl uppercase ${subPageTitle ? "mr-2" : ""}`}>
          {pageTitle}
        </span>
        {subPageTitle && (
          <small className="text-gray-dark">({subPageTitle})</small>
        )}
      </div>
    </div>
  );
};

export interface IText {
  menuName: string;
  pageTitle: string;
  subPageTitle?: string;
}
