import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

export const ToolTipComponent = ({
  id,
  children,
  arrow = true,
  direction = "bottom",
  content,
  followCursor = false,
  describeChild,
}: ITooltip) => {
  return (
    <Tooltip
      title={content}
      id={id}
      placement={direction}
      arrow={arrow}
      TransitionComponent={Zoom}
      followCursor={followCursor}
      enterDelay={300}
      leaveDelay={200}
      describeChild={describeChild}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

export interface ITooltip {
  id?: string;
  content: any;
  arrow?: boolean;
  direction?:
    | "left"
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | "top";
  followCursor?: boolean;
  describeChild?: boolean;
  children: React.ReactElement;
}
