import React from "react";
import TextField from "@mui/material/TextField";

export const TextAreaField = ({
  name,
  fieldId,
  labelName,
  onChange,
  onBlur,
  onFocus,
  value,
  errorMessage,
  valid = true,
  rows,
  onKeyUp,
  onKeyPress,
  onClick,
  variant = "outlined",
  sx,
  maxRows,
  inputRef,
  placeholder,
}: IText) => {
  return (
    <TextField
      fullWidth
      multiline
      id={fieldId}
      label={labelName}
      name={name}
      onKeyPress={onKeyPress}
      onKeyUp={onKeyUp}
      onClick={onClick}
      variant={variant}
      onChange={onChange}
      value={value}
      onFocus={onFocus}
      onBlur={onBlur}
      error={!valid}
      rows={rows}
      maxRows={maxRows}
      helperText={!valid && errorMessage}
      sx={sx}
      inputRef={inputRef}
      placeholder={placeholder}
    />
  );
};

export interface IText {
  name: string;
  fieldId: string;
  onChange: any;
  onBlur?: any;
  onFocus?: any;
  value: any;
  errorMessage?: string;
  labelName?: string;
  valid?: any;
  cols?: number;
  rows?: number;
  maxRows?: number;
  resize?: "none" | "both" | "horizontal" | "vertical";
  characterLimitCount?: any;
  onKeyPress?: any;
  disabled?: boolean;
  onClick?: any;
  onKeyUp?: any;
  variant?: "outlined" | "filled" | "standard";
  sx?: any;
  inputRef?: any;
  placeholder?: string;
}
