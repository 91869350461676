import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

export const CardComponent = ({
  title,
  subTitle,
  content,
  id,
  width,
  headerComponent,
  headerActions,
  headerAvatar,
  footerActions,
  contentSx,
  headerSx,
  sx = { maxWidth: width ? width : "100%" },
}: IText) => {
  return (
    <Card sx={sx} id={id}>
      <CardHeader
        component={headerComponent}
        avatar={headerAvatar}
        action={headerActions}
        title={title}
        subheader={subTitle}
        sx={headerSx}
      />
      <CardContent sx={contentSx}>{content}</CardContent>
      {footerActions && <CardActions>{footerActions}</CardActions>}
    </Card>
  );
};

export interface IText {
  title?: any;
  id?: any;
  subTitle?: any;
  content?: any;
  width?: any;
  headerComponent?: any;
  headerActions?: any;
  headerAvatar?: any;
  footerActions?: any;
  sx?: any;
  contentSx?: any;
  headerSx?: any;
}
